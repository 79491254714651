import { useEffect, useRef, useState } from 'react';
import { Button } from '../components/ui/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

const Header = ({
  isUnderTheHoodVisible,
  toggleUnderTheHood,
  isChatVisible,
  toggleChat,
  onLogout,
  currentUser,
  hasNewMessage
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userFullName, setUserFullName] = useState('');
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    const fetchCoachData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/account/info`, {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`
          }
        });
        console.log(response);
        setUserFullName(`${response.data.first_name} ${response.data.last_name}`);
      } catch (error) {
        console.error('Error fetching coach data:', error);
      }
    };

    fetchCoachData();

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    onLogout();
    setIsDropdownOpen(false);
  };

  return (
    <header className="bg-white-600 bg-opacity-80 backdrop-filter backdrop-blur-sm text-white p-4 flex justify-between items-center relative z-10">
      <h1 className="text-2xl font-light">
        <b>nisa</b> _controlcenter
      </h1>
      <div className="flex items-center space-x-4">
        <Button
          onClick={toggleChat}
          variant="ghost"
          className={`text-white font-quicksand border-none hover:border hover:border-blue-200 ${isChatVisible ? 'underline' : ''}`}
        >
          {isChatVisible ? 'hide nisa.chat' : 'nisa.chat'}
          {!isChatVisible && hasNewMessage && (
            <span className="absolute -top-1 -right-1 bg-red-500 rounded-full w-3 h-3"></span>
          )}
        </Button>
        <Button
          onClick={toggleUnderTheHood}
          variant="ghost"
          className={`text-white font-quicksand border-none hover:border hover:border-blue-200 ${isUnderTheHoodVisible ? 'underline' : ''}`}
        >
          {isUnderTheHoodVisible ? 'hide nisa.inside' : 'nisa.inside'}
        </Button>
        <div className="relative" ref={dropdownRef}>
          <Button
            onClick={toggleDropdown}
            variant="ghost"
            className="text-white font-quicksand border-none hover:border hover:border-blue-200"
          >
            hi, {userFullName}
          </Button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => navigate('/')}
              >
                Dashboard
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => navigate('/teacher_config')}
              >
                Teacher Config
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => navigate('/lesson_config')}
              >
                Lesson Config
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => navigate('/coach/config')}
              >
                Coach Config
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={handleLogout}
              >
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  isUnderTheHoodVisible: PropTypes.bool,
  toggleUnderTheHood: PropTypes.func,
  isChatVisible: PropTypes.bool,
  toggleChat: PropTypes.func,
  onLogout: PropTypes.func,
  currentUser: PropTypes.shape({
    accessToken: PropTypes.string
  }),
  hasNewMessage: PropTypes.bool
};

export default Header;
