import { useEffect, useState } from 'react';
import TeacherTable from '../components/TeacherTable';
import AddTeacherModal from '../components/AddTeacherModal';
import AddTeachersCSVModal from '../components/AddTeachersCSVModal';

import Header from '../components/Header';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';

export default function ConfigDashboard() {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const fetchTeachers = async () => {
    setLoading(true);
    setError('');
    try {
      const token = await currentUser.getIdToken();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/teacher/info`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTeachers(response.data);
    } catch {
      setError('Failed to fetch teacher data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  const handleTeacherAdded = () => {
    fetchTeachers();
    setIsModalOpen(false);
  };

  const handleCSVUploaded = () => {
    fetchTeachers();
  };

  async function handleLogout() {
    setError('');
    try {
      await logout();
      navigate('/login');
    } catch {
      setError('Failed to log out');
    }
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative">
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />

      <div className="fixed top-0 left-0 right-0 z-10">
        <Header
          toggleUnderTheHood={null}
          toggleChat={null}
          onLogout={handleLogout}
          currentUser={currentUser}
          hasNewMessage={false}
        />
      </div>

      <main
        className="container mx-auto p-6 flex-grow flex flex-col items-center"
        style={{ paddingTop: '100px' }}
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-8">Teacher Configuration Dashboard</h1>
        <div className="w-full max-w-5xl bg-white bg-opacity-40 rounded-lg shadow-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">Teacher Data</h2>
            <div className="flex space-x-2">
              <Button onClick={() => setIsModalOpen(true)} className="bg-green-500 text-white">
                Add Teacher
              </Button>
              <Button onClick={() => setIsCSVModalOpen(true)} className="bg-green-500 text-white">
                Add Teachers CSV
              </Button>
              <Button
                onClick={fetchTeachers}
                disabled={loading}
                className="bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700"
              >
                {loading ? 'Refreshing...' : 'Refresh'}
              </Button>
            </div>
          </div>
          <TeacherTable teachers={teachers} error={error} onRefresh={fetchTeachers} />
        </div>
      </main>

      {isModalOpen && (
        <AddTeacherModal
          onClose={() => setIsModalOpen(false)}
          onTeacherAdded={handleTeacherAdded}
        />
      )}
      {isCSVModalOpen && (
        <AddTeachersCSVModal
          onClose={() => setIsCSVModalOpen(false)}
          onCSVUploaded={handleCSVUploaded}
        />
      )}
    </div>
  );
}
