import { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { Button } from './ui/button';

export default function AddTeachersCSVModal({ onClose, onCSVUploaded }) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadResults, setUploadResults] = useState(null);
  const { currentUser } = useAuth();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type !== 'text/csv') {
      setError('Please upload a valid CSV file.');
      return;
    }
    setError('');
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError('Please select a CSV file.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const token = await currentUser.getIdToken();
      const formData = new FormData();
      formData.append('csv_file', file);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/teacher/csv`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      // Handle API response
      const { successful_uploads, errors } = response.data;
      setUploadResults({ successful_uploads, errors });

      // If we had at least one successful upload, notify parent component of
      // success
      if (successful_uploads.length > 0) {
        onCSVUploaded();
      }

      // If there were no errors, we can close the modal
      if (errors.length === 0) {
        onCSVUploaded();
        onClose();
      }
    } catch (error) {
      setError('Failed to upload teachers via CSV');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center z-50 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[90vh] overflow-hidden my-8">
        <div className="p-6 flex flex-col h-full">
          <h2 className="text-2xl font-semibold mb-4">Add Teachers via CSV</h2>
          {error && <p className="text-red-500 mb-4">{error}</p>}

          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="w-full p-2 border rounded"
            />

            <div className="flex justify-end space-x-2">
              <Button type="button" onClick={onClose} className="bg-gray-300">
                Cancel
              </Button>
              <Button type="submit" disabled={loading} className="bg-blue-500 text-white">
                {loading ? 'Uploading...' : 'Upload CSV'}
              </Button>
            </div>
          </form>
          {/* Display upload results */}
          {uploadResults && (
            <div className="mt-4">
              <h3 className="text-xl font-semibold">Upload Results</h3>
              <div className="mt-2">
                <h4 className="text-lg font-medium">Successful Uploads:</h4>
                {uploadResults.successful_uploads.length > 0 ? (
                  <ul className="list-disc list-inside">
                    {uploadResults.successful_uploads.map((teacher, index) => (
                      <li key={index}>
                        {teacher.first_name} {teacher.last_name} (
                        {teacher.phone_number.phone_number})
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No teachers were successfully uploaded.</p>
                )}
              </div>
              <div className="mt-2">
                <h4 className="text-lg font-medium text-red-500">Errors:</h4>
                {uploadResults.errors.length > 0 ? (
                  <ul className="list-disc list-inside text-red-500">
                    {uploadResults.errors.map((error, index) => (
                      <li key={index}>
                        Row {error.row}: {error.reason}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No errors occurred.</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

AddTeachersCSVModal.propTypes = {
  onClose: PropTypes.func,
  onCSVUploaded: PropTypes.func
};
